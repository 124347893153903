import React, { useEffect, useRef, useState } from 'react'
import "./Home.css"

import store from "../assets/store.png"
import building from "../assets/building.png"
import check from "../assets/check.png"
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuidv4, v4 } from 'uuid';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import db from '../Firebase'
import video from "../assets/video.mp4"
import appart from "../assets/appart3.mp4"

import elv from "../assets/elevator.png"
import elv2 from "../assets/elevator2.png"
import kit from "../assets/kitchen.png"
import kit2 from "../assets/kitchen2.png"
import buil from "../assets/time.png"
import buil2 from "../assets/time2.png"
import shield from "../assets/shield.png"
import shield2 from "../assets/shield2.png"

import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import tele from "../assets/tele.png"



import a1 from "../assets/a1.jpeg"
import a2 from "../assets/a2.jpeg"
import a3 from "../assets/a3.jpeg"
import a4 from "../assets/a4.jpeg"
import a5 from "../assets/a5.jpeg"
import a6 from "../assets/a6.jpeg"
import a7 from "../assets/a7.jpeg"
import a8 from "../assets/a8.jpeg"
import a9 from "../assets/a9.jpeg"
import m1 from "../assets/m1.jpeg"
import m2 from "../assets/m2.jpeg"
import m3 from "../assets/m3.jpeg"
import logo from  "../assets/logo.jpeg"



const Home = () => {
    const [name,setName] = useState("");
    const [number,setNumber] = useState("");
    const [email,setEmail] = useState("");
    const [type,setType] = useState("");

    const [name2,setName2] = useState("");
    const [number2,setNumber2] = useState("");
    const [email2,setEmail2] = useState("");
    const [type2,setType2] = useState(""); 

    const [show,setShow] = useState(false)
    const [show2,setShow2] = useState(false)


    const element = useRef(null);

    const handleScroll=()=>{
        element.current.scrollIntoView({behavior:'smooth'})
    }

    const sendData = async(e)=>{
        e.preventDefault();
        const id = uuidv4();
        await setDoc(doc(db, "users", id),{
            name:name,
            number:number,
            email:email,
            type:"Appartement",
            id:id,
            date: serverTimestamp(),
        });
        setName("");
        setEmail("")
        setNumber("");
        setShow(true)
    }
    const sendData2 = async(e)=>{
        e.preventDefault();
        const id = uuidv4();
        await setDoc(doc(db, "users", id),{
            name:name,
            number:number,
            email:email,
            type:"Magasin",
            id:id,
            date: serverTimestamp(),
        });
        setName("");
        setEmail("")
        setNumber("");
        setShow(true)
    }


    const Navigate = useNavigate();
    

  return (
    <div className='home'>

      <div className="hero">
        <video className='video' src={appart} controls={false} autoPlay={true} muted loop></video>
        <video className='video2' src={appart} controls={false} autoPlay={true} muted loop></video>
        <div className="heroTitle">
        
        <h1 style={{display:"none"}}>
            Nous somme pas les seules mais nous somme les meilleurs
        </h1>
      </div>
      <div className="upperHero"></div>
      <div style={{display:"none"}} className="cadre"></div>
      </div>
      <div className="hero2">
        <div className="hero2Container">
            <p>BIENVENUE</p>
            <h3>Découvrez l'élégance moderne et le confort sophistiqué de The Loft</h3>
            <p className='p2'>
            Une oasis urbaine nichée à Sidi Marouf, Casablanca. Avec des appartements résidentiels allant de 57 à 97 mètres carrés, chaque espace est méticuleusement conçu pour offrir un style de vie contemporain sans compromis. Plongez dans une expérience de vie urbaine sans pareille, où le luxe rencontre la praticité, et où chaque détail inspire une harmonie parfaite entre forme et fonction. Bienvenue chez vous, bienvenue à The Loft.
            </p>
            <button onClick={handleScroll}>Réserver Une visite</button>
        </div>
      </div>

      <div style={{display:"none"}} className="title">
        <h2>The Loft 🧡 tranche 2</h2>
        <div className="texting">
        <p>Bienvenue dans l'univers du luxe et de l'investissement immobilier avec "The Loft". À Sidi Maarouf Notre deuxième tranche vous ouvre ses portes, offrant des appartements de 57 m² à 97 m².</p>
        <div className="text">
            <div>
            <p>✅ Livraison immédiate</p>
            <p>✅ À seulement 7 minutes de Casanearshore</p>
            <p>✅ Déclaration 100%</p>
            <p>✅ Finition de qualité ✨</p>
            <p>✅ 2 et 3 chambres</p>
            <p>✅ Cuisine équipée</p>
            <p>✅ Ascenseur et parking</p>
            </div>
            <div>
                <img src={logo} alt="" />
            </div>
        </div>
        </div>
        <br />
        <center><h3>Les Appartements</h3></center>
        <center>
            <div className="line"></div>
        </center>
      </div>


<div className='part'>
    <div className='secondImg'>
        <img src={logo} alt="" />
    </div>
    <div className='secondVid'>
        <video className='video' src={video} controls={false} autoPlay={true} muted loop></video>
    </div>
</div>
<div style={{marginTop:"-30px"}} className="hero2">
        <div className="hero2Container">
            <p>Notre Vision</p>
            <h3>The Loft: Redéfinissant le Luxe Urbain</h3>
            <p className='p2'>
            Design Élégant: Des espaces ouverts et lumineux, mettant en valeur des finitions sophistiquées.
            </p>
            <p className='p2'>
            Confort Absolu: Équipements de domotique de pointe pour une vie pratique et personnalisée.
            </p>
            <p className='p2'>
            Matériaux Haut de Gamme: Sélection minutieuse de matériaux durables et élégants, allant du bois franc au quartz.
            </p>
            <p className='p2'>
            Espaces Communs Exceptionnels: Centre de remise en forme, piscine et jardins paysagers pour une vie de luxe.
            </p>
            <p className='p2'>
            Emplacement Privilégié: Situé au cœur de la ville, offrant un accès facile aux meilleurs restaurants, boutiques et divertissements.
            </p>
            <p className='p2'>
            Découvrez "The Loft" - l'expression ultime du luxe urbain.
            </p>
        </div>
      </div>

      <div className="title part1">
<center>
<h3>Appartements</h3>
</center>
        <center>
            <div className="line"></div>
        </center>
      </div>

      <div className="projects">
        <div className="projectsContainer">
            <div className="image">
                <img src={a1} alt="" />
            </div>
            <div className="image">
                <img src={a2} alt="" />
            </div>
            <div className="image">
                <img src={a3} alt="" />
            </div>
            <div className="image">
                <img src={a4} alt="" />
            </div>
            <div className="image">
                <img src={a5} alt="" />
            </div>
            <div className="image">
                <img src={a6} alt="" />
            </div>

            <div className="image">
                <img src={a7} alt="" />
            </div>
            <div className="image">
                <img src={a8} alt="" />
            </div>
            <div className="image">
                <img src={a9} alt="" />
            </div>
            <div className="image">
                <img src={a3} alt="" />
            </div>



        </div>
      </div>

      <div className="title">
<center>
<h3>Magasins</h3>
</center>
        <center>
            <div className="line"></div>
        </center>
      </div>

      <div className="projects">
        <div className="projectsContainer2">
            <div className="image">
                <img src={m1} alt="" />
            </div>
            <div className="image">
                <img src={m2} alt="" />
            </div>
            <div className="image">
                <img src={m3} alt="" />
            </div>
        </div>
      </div>

<br /><br />
      <div className="hero2">
        <div className="hero2Container">
            <p>SPÉCIALISATION</p>
            <h3>Nous sommes innovants</h3>

            <div className="quality">
                <div className="box">
                    <div className="boxContent">
                        <center>
                            <img className='img' src={elv} alt="" />
                            <img className='img2' src={elv2} alt="" />
                            <h2>Ascenseur</h2>
                        </center>
                    </div>
                </div>
                <div className="box">
                <div className="boxContent">
                <center>
                    <img className='img' src={kit} alt="" />
                    <img className='img2' src={kit2} alt="" />
                    <h2>Cuisine équipée</h2>
                </center>
            </div>
            </div>
                <div className="box">
                <div className="boxContent">
                        <center>
                            <img className='img' src={buil} alt="" />
                            <img className='img2' src={buil2} alt="" />
                            <h2>Livraison immediate</h2>
                        </center>
                    </div>
                </div>
                <div className="box">
                <div className="boxContent">
                        <center>
                            <img className='img' src={shield} alt="" />
                            <img className='img2' src={shield2} alt="" />
                            <h2>Finition de qualité</h2>
                        </center>
                    </div>
                </div>

            </div>
        </div>
      </div>

      <div style={{marginTop:"-120px",marginBottom:"-50px"}} className="hero2">
        <div className="hero2Container">
            <p>Localisation</p>
            <h3>Visitez-nous pour en savoir plus</h3>
        </div>
      </div>
      <div className="maps">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.1423967356427!2d-7.664227467948498!3d33.497672149207006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62d8dfd249a1b%3A0xbbdff6ea577ad915!2sPalmiers%20Sidi%20Maarouf!5e0!3m2!1sar!2sma!4v1707967688041!5m2!1sar!2sma" width="95%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <div ref={element} className="forms">
        <div className="formContainer">

        <div className="title">
        <p style={{width:"90%",marginLeft:"9%"}} className='contactTitle'>CONTACTEZ-NOUS</p>
        <h2 style={{color:"white"}}>Notre Bureau de vente ouvert 7j/7 de 9h à 19h</h2>
      <h5 style={{fontFamily:"sans-serif",marginBottom:"10px",fontSize:"20px",width:"85%",marginLeft:"9%",color:"white"}}>Formulaire de Visite de Projet Immobilier</h5>
        <div style={{width:"90%",marginLeft:"9%"}}>
        <p style={{color:"white",fontFamily: "Century Gothic"}}>Votre adresse email ne sera pas publiée. Les champs requis sont indiqués*</p>
        </div>
        <center>
            <div className="line"></div>
        </center>
      </div>

      <div className="formBox">
        <form onSubmit={sendData}>
            <div className='frm'>
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <h4>Appartements</h4>
            <img src={building} alt="" />
            </div>
            <p>Nom complet*</p>
            <input onChange={(e)=> setName(e.target.value)} value={name} type="text" required/>
            <p>Numero telephone*</p>
            <input onChange={(e)=> setNumber(e.target.value)} value={number} type="text" required/>
            <p>E-mail*</p>
            <input onChange={(e)=> setEmail(e.target.value)} value={email} type="text" required/>
            <p>Date de preference pour la visite*</p>
            <input onChange={(e)=> setType(e.target.value)} value={type} type="date" required/>
            <center>
            <button type='submit'>Reserver</button>
            {show && (
                <div className="message">
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <p>Votre demande a ete bien envoyer</p>
                <img src={check} alt="" />
                </div>
                    </div>
            )}
            </center>
            
            </div>
        </form>

        <form onSubmit={sendData2}>
            <div className='frm'>
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <h4>Magasins</h4>
            <img src={store} alt="" />
            </div>
            <p>Nom complet*</p>
            <input onChange={(e)=> setName2(e.target.value)} value={name2} type="text" required/>
            <p>Numero telephone*</p>
            <input onChange={(e)=> setNumber2(e.target.value)} value={number2} type="text" required/>
            <p>E-mail*</p>
            <input onChange={(e)=> setEmail2(e.target.value)} value={email2} type="text" required/>
            <p>Date de preference pour la visite*</p>
            <input onChange={(e)=> setType2(e.target.value)} value={type2} type="date" required/>
            <center>
            <button type='submit'>Reserver</button>

            {show2 && (
                <div className="message">
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <p>Votre demande a ete bien envoyer</p>
                <img src={check} alt="" />
                </div>
                    </div>
            )}
            </center>
            </div>
        </form>
      </div>

      <div className="sociaux">
        <div className="sociauxContainer">
            <h2>Contacez Nous</h2>
            <div className='so'>
            <img src={tele} alt="" />
                <p>+212 687549045</p>
            </div>
            <div className='so'>
            <img src={tele} alt="" />
                <p>+212 601060643</p>
            </div>
            <a target='_blank' href='https://www.instagram.com/theloft.ma?igsh=dHd5ZnlvdXd6eGhx' className='so'>
                <img src={instagram} alt="" />
                <p>@theloft.ma</p>
            </a>
            <a target='_blank' href='https://www.facebook.com/profile.php?id=100090576890623&mibextid=ZbWKwL' className='so'>
                <img src={facebook} alt="" />
                <p>The loft | Casablanca</p>
            </a>
            <center>
            <div className="rights">
                <p>Copyright © The loft All rights reserved.</p>
            </div>
            </center>
        </div>
      </div>
        </div>
      </div>
    </div>
  )
}

export default Home
