import React, { useEffect, useState } from 'react'
import './Admin.css'
import { collection, deleteDoc, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import db from '../Firebase';
import { Link } from 'react-router-dom';
const Admin = () => {
    const [logged,setLogged] = useState(false);
    const [username,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [users,setUsers] = useState([]);
    const [user,setUser] = useState();
    const [filteration,setFiltration] = useState("email")
    const [option,setOption] = useState("email");
    const [unsubscribe, setUnsubscribe] = useState(null);
    const [message,setMessage]= useState(false)
    const [searchInput, setSearchInput] = useState("");




    const login = async(e)=>{
      e.preventDefault();
      const docRef = doc(db, "admin", "security");
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  if (docSnap.data().userName === username && docSnap.data().password === password) {
    setLogged(true);
  }else{
    alert("wrong Username/Password");
  }
} else {
  alert("wrong Username/Password");
}
    }

    const deleteUser = async(id)=>{
      setMessage(true)
      setUser(id)
  }
  
    const deleteU=async()=>{
      await deleteDoc(doc(db, "users", user));
      setMessage(false)
  }


    const getData=()=>{
      const q = query(collection(db, "users"));

      const newUnsubscribe = onSnapshot(q, (querySnapshot) => {
        const newArray = [];
        querySnapshot.forEach((doc) => {
          newArray.push({ email: doc.data().email, time: JSON.stringify(doc.data().date.toDate()),name: doc.data().name,number:doc.data().number,id:doc.data().id,type:doc.data().type});
        });
        setUsers(newArray);
      });
  
      // Unsubscribe the previous listener if it exists
      if (unsubscribe) {
        unsubscribe();
      }
  
      // Save the new listener reference
      setUnsubscribe(() => newUnsubscribe);
  }

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };


  const convertArray = (arr)=>{
    return arr.join("\n")
  }

  const getTxt =()=>{
    const array =[]
    users.forEach((item)=>{
        array.push(item.email)
    })
        
    const blob = new Blob([convertArray(array)], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
link.download = "emails.txt";
link.href = url;
link.click();
  }

  useEffect(()=> {
    getData();
    return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
},[]);

useEffect(()=>{
  if(filteration === "email"){
      setOption("email")
  }else{
      setOption("date")
  }
},[filteration]);

  return ( 
    <div className='admin'>
      {logged ? (
        <div className="admin">
        {message && (
            <div className="message">
            <div className="messageBox">
                <h1>هل تريد حذف هذا الحساب</h1>
                <div className='choiceButtons'>
                    <button onClick={()=> setMessage(false)}>لا</button>
                    <button onClick={()=> deleteU()}>نعم</button>
                </div>
            </div>
            </div>
        )}
       <div className="heading">
       <div className="head">
            <div className="info">
            <p className='name'>The loft</p> 
            <p style={{width:"150px",marginLeft:"10px"}}>Users: {users.length}</p>
            </div>
            <div style={{display:"none"}} className="info2">  
            <Link style={{textDecoration:"none"}} to="/price-control"><p>Gestion des prix</p></Link>
            <Link style={{textDecoration:"none"}} to="/printbtp"><p>Total</p></Link>

            </div>
           
           <div style={{display:'flex',alignItems:"center"}} className="r">
           <button style={{marginRight:"10px",backgroundColor:"blue"}} onClick={getTxt}>Extract mails</button>
            <button onClick={""}>Logout</button>
           </div>
        </div>
       </div>





       <div className="heading">
       <div className="head">
            <div className="info">
            <p className='name'>Filtrer</p>
            <select style={{marginLeft:"5px",height:"25px",width:"80px"}} onChange={(e)=> setFiltration(e.target.value)}>
                <option value="email">Email</option>  
                 {/*<option value="time">date</option>*/}
            </select>
            <p><input className='searchInput' placeholder='Email adress' value={searchInput} onChange={(e)=> handleChange(e)} type="text" /></p>
            </div>
            <div style={{display:"none"}}>
              <input style={{marginRight:"5px",height:"26px",borderRadius:"2px",border:"1px solid gray",paddingLeft:"5px",outline:"none"}} type="text" placeholder='Change password'/>
            <button onClick={''} style={{backgroundColor:"blue"}}>save</button>
            </div>
        </div>
       </div>




       <div className="users">
        <div className="usersContainer">
        <div className='email'>
                    <div className="emailInfos">
                    <p className='adr2'>Email</p>
                    <p className='adr22' style={{fontSize:12}}>Name</p>
                    <p className='adr22' style={{fontSize:12}}>Phone</p>
                    <p className='adr22' style={{fontSize:12}}>Type</p>
                    <p className='date2' style={{fontSize:12}}>Date</p>
                    <p className='date2' style={{fontSize:12}}>Time</p>


                    </div>
                </div>
        {users.filter((val)=> {
            if (option === "email") {
                if (searchInput == "") {
                    return val;
                }else if(val.email.includes(searchInput.toLowerCase())){
                    return val
                }
            }else{
                if (searchInput == "") {
                    return val;
                }else if(val.time.includes(searchInput.toLowerCase())){
                    return val
                }
            }
            
        }).map((user)=> (
            <>
                <div className='email'>
                    <div className="emailInfos">
                    <p className='adr'>{user?.email}</p>
                    <p className='adrr2'>{user?.name}</p>
                    <p className='adrr2'>{user?.number}</p>
                    <p className='adrr2'>{user?.type}</p>
                    <p className='date' style={{fontSize:12}}>{user?.time?.substring(1,11)}</p>
                    <p className='date' style={{fontSize:12}}>{user?.time?.substring(12,17)}</p>
                    </div>
                    <button onClick={()=> deleteUser(user?.id)}>x</button>
                </div>
                </>
            ))} 
        </div>
       </div>
    </div>
      ):(
        <div className="loginBox">
          <form onSubmit={login} className="loginContainer">
            <center>
              <h1>Welcome back !</h1>
              <div className='label'>
                <label>Username</label>
              </div>
            <input onChange={(e)=> setUserName(e.target.value)} type="text" required/>
            <div className='label'>
                <label>Password</label>
              </div>
            <input onChange={(e)=> setPassword(e.target.value)} type="password" required/><br />
            <button type='submit'>Login</button>
            </center>
          </form>
        </div>
      )}
    </div>
  )
}

export default Admin
