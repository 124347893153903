import React, { useState } from 'react'
import "./Nav.css"
import { Link } from 'react-router-dom'
import logo from  "../assets/logo.jpeg"
import menu from  "../assets/menu.png"
import closeImg from  "../assets/close.png"

const Nav = () => {
  const [nav,setNav] = useState(false);

  const handleScroll=()=>{
    setNav(false)
    window.scrollTo(0,6700);
}
  return (
    <div className='navContainer'>
      <div className='nav'>
        <Link style={{textDecoration:"none"}} to={"/"}>
        <img src={logo} alt="" />
        </Link>
        <img onClick={()=> setNav(true)} className='burger' src={menu} alt="" />
      </div>
      <div className={nav ? "menu" : "menu2"}>
        <div className="menuHeader">
          {nav && (<img onClick={()=> setNav(false)} className='close' src={closeImg} alt="" />)}
        </div>
        <div className="menuBody">
          <center>
          <h2>Navigation</h2>
          <Link to="/page1" style={{textDecoration:"none",color:"black"}} onClick={()=> setNav(false)}><p>Photos</p></Link>
          <p onClick={handleScroll}>Reserver une date</p>
          </center>
        </div>
      </div>
    </div>
  )
}

export default Nav
