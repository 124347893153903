import React, { useState } from 'react'
import "./Pages.css"
import left from "../assets/left.png"
import right from "../assets/right.png"
import a1 from "../assets/a1.jpeg"
import a2 from "../assets/a2.jpeg"
import a3 from "../assets/a3.jpeg"
import a4 from "../assets/a4.jpeg"
import a5 from "../assets/a5.jpeg"
import a6 from "../assets/a6.jpeg"
import a7 from "../assets/a7.jpeg"
import a8 from "../assets/a8.jpeg"
import a9 from "../assets/a9.jpeg"



const Page1 = () => {
  const [choosed,setChoosed] = useState();
  const [show,setShow] = useState(false);
  const [iamges,setImages] = useState([
    {
      imgUrl:a1,
    },{
      imgUrl:a2,
    },{
      imgUrl:a3,
    },{
      imgUrl:a4,
    },{
      imgUrl:a5,
    },{
      imgUrl:a6,
    },{
      imgUrl:a7,
    },{
      imgUrl:a8,
    },{
      imgUrl:a9,
    }
  ]);

  const goForward=()=>{
    if (choosed === iamges.length - 1) {
      setChoosed(0);
    }else{
      setChoosed(prev => prev + 1);
    }
  }
  const goBackward=()=>{
    if (choosed === 0) {
      setChoosed(iamges.length - 1);
    }else{
      setChoosed(prev => prev - 1);
    }
  }

  return (
    <div className='page'>
      <div className="pageContainer">
        {show && (
          <div className="showedImage">
          <div className="showedImageContainer">


            <img key={choosed} className='img' src={iamges[choosed].imgUrl} alt="" />

            <button onClick={goBackward} className='but1'><img src={left} alt="" /></button>

            <button onClick={goForward} className='but2'><img src={right} alt="" /></button>
            <p className='exit' onClick={()=> setShow(false)}>x</p>

          </div>
        </div>
        )}
        <h2>The loft</h2>

        <div className="pageImages">
          {iamges.map((item,index) => { return(
            <img onClick={()=> {setChoosed(index);setShow(true)}} src={item.imgUrl} alt="" />
          )})}
        </div>

      </div>
    </div>
  )
}

export default Page1
 