import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Nav from './Pages/Nav';
import Page1 from './Pages/Page1';
import Admin from './Pages/Admin';

function App() {
  return (
    <Router>
      <Nav/>
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/page1" element={<Page1 />} />
          <Route exact path="/admin" element={<Admin />} />

      </Routes>
    </Router>
  );
}

export default App;
